import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
      <h1>Privacy Policy</h1>
      <p>Thank you for visiting ShareME.chat website ("Site"). This Privacy Policy details certain policies implemented throughout the website governing [Customer Name]'s use of personally identifiable information about users of our Site and users of our services and/or software that is available for download on this Site. This policy is maintained by ShareME.chat, a company under the laws of the Province of Alberta. Canada. ShareME.chat is also referred to in this Policy as "we" and "us".</p>
      <h2>Privacy Policy Updates</h2> 
      <p>Due to the Internet's rapidly evolving nature, We may need to update this Privacy Policy from time to time. If so, We will post its updated Privacy Policy on ShareMe.chat. We so you are always aware of what personally identifiable information we may collect and how we may use this information. We encourage you to review this Privacy Policy regularly for any changes. Your continued use of this Site and/or continued provision of personally identifiable information to us will be subject to the terms of the then-current Privacy Policy.</p>
      <h2>Information Collection and Use </h2>
      <p>You can generally visit our Site without revealing any personally identifiable information about yourself. However, in certain sections of this Site, we may invite you to contact us with questions or comments or request information, provide us with feedback, or complete a profile or registration form. Due to the nature of some of these activities, we may collect personally identifiable information such as your name, address, email address, phone number, password, credit card number, and other contact information that you voluntarily transmit with your communication to us. We also may collect information about you and your use of our Site, services and/or software through your use of our Site, services and/or software. </p>
      <p>The Site may track the total number of visitors to our Site, the number of visitors to each page of our Site, browser type, photo data, metadata, operational metadata, and other data related to the Site such as IP addresses, External Web Sites (defined below) linked to, and we may analyze this data for trends and statistics in the aggregate, but such information will be maintained, used and disclosed in aggregate form only and it will not contain personally identifiable information. We may use such aggregate information to analyze trends, administer the Site, track users' movement, and gather broad demographic information for aggregate use.</p>
      <h2>Disclosure</h2>
      <p>We may provide your personally identifiable information and the data generated by cookies and the aggregate information to the vendors and service agencies that we may engage to assist us in providing our services to you. For example, we may provide your personally identifiable information to a credit card processing company to process your payment and our third-party licensors and hosting providers to provide this Site and services to you. We will disclose your personally identifiable information if we reasonably believe we are required to do so by law, regulation or other government authority. We will not sell your personally identifiable information to any company or organization except we may transfer your personally identifiable information to a successor entity upon a merger, consolidation or other corporate reorganization in which We participate or to a purchaser of all or substantially all of  [Customer Name]'s assets to which this Site relates.</p>
      <h2>Links to Third Party Sites</h2> 
      <p>The Site may provide links to other Web sites or resources over which We do not have control ("External Websites"). Such links do not constitute an endorsement by We of those External Websites. You acknowledge that We are providing these links to you only as a convenience, and further agree that We is not responsible for the content of such External Websites. Your use of External Websites is subject to the terms of use and privacy policies located on the linked to External Websites.</p>
      <h2>Security</h2>
      <p>We may employ procedural and technological measures, consistent with industry practice. Such measures are reasonably designed to help protect your personally identifiable information from loss, unauthorized access, disclosure, alteration or destruction. We may use [firewalls, password protection, secure socket layer,] and other security measures to help prevent unauthorized access to your personally identifiable information.</p>
      <p>If you have any questions regarding this Privacy Policy, please contact us via email at info@shareme.chat</p>
    </LongContent>
  );
}

export default LegalPrivacy;
