import axios from 'axios'
import jwtDecode from "jwt-decode";
// Delay to mimic slower network requests
const RESPONSE_DELAY = 200;
// Can increment to prevent old storage data from being used
const STORAGE_VERSION = "fa5";

const baseURL = 'https://api.callwidget.co';
const headers = { 'Content-Type': 'application/json' }
const callwidget = axios.create({
    baseURL,
    headers,
    timeout: 10000,
})

const CallWidgetAuth = {
  onChangeCallback: null,
  
  getToken: function(){
    return storeGet("access-token")
  },

  getCurrentUser: function () {
    const token = storeGet("access-token");
    return getAuth(token).then((auth) => auth.user);
  },

  signin: function (email, password) {
    console.log('callwidget - signin called')
   
    return getCallWidgetToken(email,password).then(async (token) => {
      try {
        const tokenData = jwtDecode(token)
        const auth = false
        console.log('auth found in db', auth)
        const {data: { data: profile }} = await getProfile(token)
        this.changeAccessToken(token);
        if (!auth) { 
          console.log('creating user')
          // Create auth object
          const user = { uid: tokenData.sub.toString(), email, ...profile };
        
          const newAuth = { user, token };
         
          // Store auth object and signin user
          return addAuth(newAuth).then(() => {
            this.changeAccessToken(newAuth.token);
            return newAuth;
          });
        }
        console.log('user already exists')
      
        return { token, profile, user: { uid: profile.id.toString(), name: profile.name, email, ...profile }}
      } catch(error){
        throw new CustomError("auth/token", "Unable to retrieve profile with token")
      }
    }).catch(() => {
      throw new CustomError("auth/bad-credentials", "Unable to login")
    })
  },

  signout: async function () {
    // Signout user
    this.changeAccessToken(null);
    return Promise.resolve();
  },

  onChange: function (cb) {
    // Store callback function so we can also call within
    // setAccessToken(). Necessary because storage event listener
    // only fires when local storage is changed by another tab.
    this.onChangeCallback = cb;

    const handleTokenChange = (token) => {
      getAuth(token).then((auth) => {
        this.onChangeCallback(auth || false);
      });
    };

    const listener = window.addEventListener(
      "storage",
      ({ key, newValue }) => {
        console.log('storage event', key, newValue)
        if (key === "access-token") {
          handleTokenChange(JSON.parse(newValue));
        }
      },
      false
    );

    const accessToken = storeGet("access-token");
    handleTokenChange(accessToken);

    // Return an unsubscribe function so consumer
    // can unsubscribe when needed.
    return () => {
      window.removeEventListener("storage", listener);
    };
  },

  // updateProfile: function (data) {
  //   return updateAuthForCurrentUser(data).then((updatedAuth) => {
  //     return updatedAuth.user;
  //   });
  // },

  // Updates access token in storage and calls onChangeCallback()
  changeAccessToken: function (accessToken) {
    storeSet("access-token", accessToken);
    // If we have an onChangeCallback (set in this.onChange)
    if (this.onChangeCallback) {
      // Fetch user via accessToken and pass to callback
      getAuth(accessToken).then((auth) => {
        this.onChangeCallback(auth || false);
      });
    }
  },

  getAccessToken: function () {
    return storeGet("access-token");
  },
};

/***** CALLWIDGET  ******/

const getCallWidgetToken = async (email, password) => {
  console.log('-_-_-_-_  getting token from callwidget')
    const resp = await callwidget.post('/api/v1/api-login', {
        email, password
    })
    return resp.data.access_token
}

const getProfile = (token) => {
  console.log('getProfile from callwidget - getBusiness', !!token)
  if (token) return callwidget.get('/api/v1/profile', { headers: { Authorization: `Bearer ${token}` } })
  return Promise.reject()
}

/***** LOCAL DB *****/

const _getAll = () => storeGet("auth-db", []);
const _setAll = (db) => storeSet("auth-db", db);

const getAuth = (token) => {
  return delay(() => _getAll().find((item) => item?.token === token));
};

const addAuth = (auth) => {
  return delay(() => {
    const all = _getAll();
    all.push(auth);
    _setAll(all);
  });
};

const updateAuthForCurrentUser = (userData) => {
  const accessToken = storeGet("access-token");
  if (!accessToken) {
    throw new CustomError(
      "auth/not-signed-in",
      `You must be signed in to perform this action`
    );
  }

  return updateAuth(accessToken, userData);
};

const updateAuth = (token, userData = {}) => {
  return delay(() => {
    const all = _getAll();
    const index = all.findIndex((item) => item.token === token);

    if (index !== -1) {
      all[index] = {
        ...all[index],
        user: {
          ...all[index].user,
          ...userData,
        },
      };

      _setAll(all);
      return all[index];
    } else {
      return false;
    }
  });
};

/***** HELPERS *****/

function storeGet(key, defaultValue = null) {
  const value = window.localStorage.getItem(`${key}-${STORAGE_VERSION}`);
  return value ? JSON.parse(value) : defaultValue;
}

function storeSet(key, value) {
  window.localStorage.setItem(
    `${key}-${STORAGE_VERSION}`,
    JSON.stringify(value)
  );
}

function storeRemove(key) {
  window.localStorage.removeItem(`${key}-${STORAGE_VERSION}`);
}

const delay = (cb) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(cb());
    }, RESPONSE_DELAY)
  );
};

function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

CustomError.prototype = Object.create(Error.prototype);

export default CallWidgetAuth