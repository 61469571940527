import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Section from "./../components/Section";

function GoToShareme(props) {
  console.log('props', props)
  
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
          <Grid
          container={true}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
          spacing={4}
        >
          <Grid item={true} xs={12} md={6}></Grid>
           <Button
            href="https://chat.shareme.chat/business/automation/"
            variant="contained"
            size="large"
            color={props.buttonColor}
          >
            {props.buttonText}
          </Button>
          </Grid>
       
      </Container>
    </Section>
  );
}

export default GoToShareme;
