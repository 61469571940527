import React from "react";
import Meta from "./../components/Meta";
import CtaSection from "./../components/CtaSection";
import NewsletterSection from "./../components/NewsletterSection";
import FeaturesSection2 from "./../components/FeaturesSection2";
import { requireAuth } from "./../util/auth";
import { useRouter } from "./../util/router";
import { getUser } from './../util/db'
const descriptions = {
  lightspeed: "Integrate your Lightspeed R-series POS with Shareme.chat to send your customers order notification texts or emails and request reviews for orders.",
  computyme: "Integrate your Computyme POS with Shareme.chat to send your customers order notification texts or emails and request reviews for orders.",
  clover: "Integrate your Clover POS with Shareme.chat to send your customers order notification texts or emails and request reviews for orders.",
}
function IntegrationPage(props) {
  const router = useRouter()
  
  return (
    <>
      <Meta title="Integrations" />
      <CtaSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={router.query.integration[0].toUpperCase() + router.query.integration.substring(1)}
        key={router.query.integration}
        subtitle={descriptions[router.query.integration]}
        buttonText="Install"
        buttonColor="primary"
        buttonPath="/pricing"
      />
      {/* <NewsletterSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      /> */}
      <FeaturesSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      />
    </>
  );
}

export default requireAuth(IntegrationPage);
