import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "./../util/router";
import Section from "./../components/Section";
import SectionHeader from "./../components/SectionHeader";
import Image from "mui-image";
import { useAuth } from "../util/auth";
import { useDarkMode } from "./../util/theme";
import axios from "axios";
import CallWidgetAuth from "../util/callwidget";
import { Call } from "@material-ui/icons";
import { useBusiness, getUser, useItemsByOwner } from "./../util/db";

const getUrl = (type, id) => {
  switch(type){
    case 'lightspeed':
      return   `https://cloud.lightspeedapp.com/oauth/authorize.php?response_type=code&client_id=900ad69f9e8c523e9b8a8bae71e756bc2281fa505aa7e840e310c77beb30382f&scope=employee:all&state=${id}`
    default:
      return ''
   }

}

const useStyles = makeStyles((theme) => ({
  // root: {
  //   fill: '#fff',
  //   '& label': {
  //     color: '#fff',
  //   },
  //   '& label.Mui-focused': {
  //     color: '#fff',
  //   },
  //   '& .MuiInput-underline:after': {
  //     borderBottomColor: '#fff',
  //   },
  //   '& .MuiOutlinedInput-root': {
  //     '& fieldset': {
  //       borderColor: '#fff',
  //       color: '#fff',
  //     },
  //     '&:hover fieldset': {
  //       borderColor: '#fff',
  //       borderWidth: '0.15rem',
  //     },
  //     '&.Mui-focused fieldset': {
  //       borderColor: '#fff',
  //     },
  //   },
  // },
  select: {
  '& label.Mui-focused': {
    color: theme.palette.primary.light,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      'border-color': theme.palette.primary.light,
    }
  },
    '&:before': {
        borderColor: theme.palette.primary.light,
        
    },
    '&:after': {
        borderColor: theme.palette.primary.light,
    },
    "& option": {
      background: theme.palette.background.paper
    }
  }
}));

function CtaSection(props) {
  const classes = useStyles();
  const darkMode = useDarkMode();
  const token = CallWidgetAuth.getToken();
  const selectClass = darkMode.value ? classes.root : ''
  console.log('props', props)
  const auth = useAuth()
  console.log('----', auth.user.business.id)
  const {
    data: business,
    status: businessStatus,
    error: businessError,
  } = useBusiness(auth.user.business.id);

  const {
    data: user,
    status: userStatus,
    error: userError,
  } = getUser(auth.user.uid);


    const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useItemsByOwner(auth.user.uid);

  const integrationEnabled = !!business?.integrations && !!business?.integrations[props.title.toLowerCase()]
  
  console.log('enabled business -------', business, user, items)
  const [install, setInstall] = React.useState('');

  const handleChange = (event) => {
    setInstall(event.target.value);
  };
  
  const image = props.title === 'Lightspeed' ? 'https://assets.lightspeedhq.com/img/9046ab39-lightspeed-share-image.jpg' : props.title === 'Computyme' ? '/images/ctyme.png' : "/images/clover-network-inc-vector-logo.png"
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
      
          <Grid
          container={true}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
          spacing={4}
        >
          <Grid item={true} xs={12} md={12}>
              <Image src={image} duration={0} position="relative" width="400px" />
          </Grid>
          <Grid item={true} xs={12} md={12}>
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={4}
            />
          </Grid>
          {/* <FormControl fullWidth> */}
          
          <Grid item={true} xs={12} md={12} sm={12}>
            
          
            <Grid item={true} md={12} xs={12}>
            <FormControl className={classes.root} sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="select-install-label">Select Install</InputLabel>
              <Select
                  className={selectClass}
                  style={{ minWidth: 200, maxWidth: 400, borderColor: (theme) => theme.palette.primary.main }}
                  labelId="select-install-label"
                  id="simple-select"
                  value={install}
                  fullWidth={true}
                  label="Select Install"
                  onChange={handleChange}
                >{auth.user.business.installs.filter(i => !!i.number).map(i => <MenuItem value={`${i.id}-${i.number}`}>{i.number}</MenuItem>)}</Select>
                   </FormControl>
            {/* </Grid> */}
          </Grid>
          </Grid>
          <Grid item={true} xs={12} md={6}></Grid>
           <Button

            onClick={() => { axios.post('https://eoz32rmbkvi5v5p.m.pipedream.net', { install: install, integration: props.title.toLowerCase(), business: auth.user.business.id }, { headers: { 'x-api-token': token } })}}
            href={getUrl(props.title.toLowerCase(), auth.user.business.id)}
            variant="contained"
            size="large"
            disabled={false}
            color={props.buttonColor}
          >
            {props.buttonText}
          </Button>
          </Grid>
       
      </Container>
    </Section>
  );
}

export default CtaSection;
