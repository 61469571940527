import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import ContentCardsSection from "./../components/ContentCardsSection";
import { requireAuth } from "./../util/auth";

function IntegrationsPage(props) {
  return (
    <>
      <Meta title="Integrations" />
      <HeroSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Integrations"
        subtitle=""
      />
      <ContentCardsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Available Integrations"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(IntegrationsPage);
