import React from "react";
import LongContent from "./LongContent";
function LegalTerms(props) {
  return (
    <LongContent>
      <h1>Terms of Service</h1>
      Terms of Service.
 
Please read these Terms of Service ("Terms") carefully as they contain the legal terms and conditions that you agree to when you use the digital photography services provided to you by ShareMe.chat online video chat service, provided through ShareMe.chat, or co-branded partner (collectively, the "Service").
​By using our Service you agree to be bound by Part A of these Terms which contains provisions applicable to all users of our Service, including casual visitors. If you choose to register as a member of, or make a purchase through, our Service, you will be asked to check a box to indicate that you have also read and agree to be bound by the additional wording set out in Part B of these Terms.
Part A: Provisions applicable to all Users of the Service (Whether Registered or Not)
​
<p>1. Operator</p>
This Service is operated by ShareMe.chat ("ShareMe.chat"), a company under the laws of Alberta, Canada.
ShareMe.chat is also referred to in these Terms as "we" and "us".
​
<p>2. Purpose of the Service</p>
ShareMe.chat offers Video chat services that provide the ability to view, share, and chat through a range of electronic devices including personal computers and hand-held devices.
​
<p>3. Availability</p>
ShareMe.chat uses reasonable endeavours to ensure that the Service is available 24 hours a day 7 days a week. However, there will be occasions when the Service will be interrupted for maintenance, upgrades and emergency repairs or due to failure of telecommunications links and equipment that are beyond the control of ShareMe.chat... reasonable steps will be taken by ShareMe.chat to minimize such disruption where it is within the reasonable control of ShareMe.chat, You agree that ShareMe.chat shall not be liable to you for any modification, suspension or discontinuance of the Service.
​
<p>4. Privacy</p>
ShareMe.chat has a firm commitment to safeguarding your privacy. The terms of ShareMe.chat privacy policy are incorporated into, and considered part of, these Terms.
​
<p>5. Trademarks</p>
All brand product and service names used in this Service that identifies ShareMe.chat or third parties and their products and services are proprietary marks of ShareMe.chat, and/or the relevant third parties. Nothing in this Service shall be deemed to confer on any person any license or right on the part of ShareMe.chat or any third party with respect to any such image, logo or name.
​
<p>6. Copyright</p>
ShareMe.chat is unless otherwise stated, the owner of all copyright and database rights in the Service and its contents. You may not publish, distribute, extract, re-utilize or reproduce any such content in any material form (including photocopying or storing it in any medium by electronic means) other than in accordance with the limited use license set out in our copyright notice.
​
<p>7. Reporting Violations of Your Copyrights</p>
If you believe the copyright in your work has been violated through this Service, please contact ShareMe.chat at info@ShareMe.chat. You must provide the following information, which ShareMe.chat may then forward to the alleged infringer:
​
(a) identify the material on the Service that you believe infringes your work, with enough detail so that we may locate it on the Service;
​
(b) provide your address, telephone number, and email address;
​
(c) provide a statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
​
(d) provide a statement that (i) the information you have provided to us is accurate, and (ii) you are the owner of the copyright interest involved or you are authorized to act on behalf of that owner; and
​
(e) provide your physical or electronic signature. Upon receiving your complaint, ShareMe.chat may remove content that you believe infringes your copyright. In addition, ShareMe.chat may terminate the account of the member who appears to be infringing your intellectual property rights.
​
<p>8. External Links</p>
From time to time ShareMe.chat may provide links that will take you to other, third party websites. These links are provided for your convenience. If you decide to access linked websites you do so at your own risk. ShareMe.chat does not endorse or take responsibility for the content on other websites or the availability of other websites and you agree that ShareMe.chat is not liable for any loss or damage that you may suffer by using other websites.
​
<p>9. Amendment of the Terms</p>
We reserve the right to amend these Terms from time to time. If you have registered as a member, we shall notify you of any material changes to these Terms by email sent to the address you have provided to ShareMe.chat for your account. If you continue to use the Service once you have been notified of the changes to these Terms, you will be deemed to have accepted those changes.
​
<p>10. Applicable Law and Jurisdiction</p>
These Terms shall be governed by and construed in accordance with the laws of the Province of Alberta. Any disputes arising under or in connection with these Terms shall be subject to the non-exclusive jurisdiction of the Province (Alberta) and Federal courts of Canada.
​
<p>11. General</p>
These Terms constitute the entire agreement between ShareMe.chat and you with respect to your use of the Service. ShareMe.chat's failure to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision. If a court should find that one or more rights or a provision contained in these Terms are invalid, you agree that the remainder of the Terms shall be enforceable.
​
<p>12. Comments</p>
If you have any comments or questions about the Service please contact us by email at info@ShareMe.chat
​
Part B: Provisions Applicable to Registered Members and Customers
​
<p>13. Registration</p>
To purchase from or register as a member of the Service you must be 18 years or over.
You agree that the information that you provide to us on registration and at all other times will be true, accurate, current and complete. You also agree that you will ensure that this information is kept accurate and up to date at all times. This is especially important with respect to your email address since that is the primary way in which we will communicate with you about your account and your orders.
​
<p>14. Password</p>
When you register as a member you will be asked to provide a password. As you will be responsible for all activities that occur under your password, you should keep your password confidential. You must notify ShareMe.chat immediately of any unauthorized use of your password or if you believe that your password is no longer confidential. We reserve the right to require you to alter your password if we believe that your password is no longer secure.
​
<p>15. Formation of a Binding Contract</p>
No contract will exist between you and ShareMe.chat for the supply of any services or products unless and until ShareMe.chat accepts your order by a confirmatory e-mail, SMS/MMS message or other appropriate means of communication.
​
<p>16. Pricing and Delivery</p>
All prices are stated in US dollars or CDN dollars and are valid until altered by ShareMe.chat. Prices do not include delivery charges or any import duties that may be added by the order destination country. 
​
<p>17. Monitoring of Content</p>
ShareMe.chat does not control the content of members' accounts and does not have any obligation to monitor such content for any purpose. You acknowledge that you are solely responsible for all content and material you provide to the Service.
​
<p>21. Suspension and Termination of Access and Membership</p>
You agree that ShareMe.chat may at any time without notice:
​
a.       refuse to accept your application to become a member of ShareMe.chat;
​
b.       move or suspend any part of the Service; or
​
c.       refuse to fulfill any order, or any part of any order or terminate your account and delete any content stored in your account if, in ShareMe.chat's sole discretion, you fail to comply with any of these Terms or if a competent regulatory authority requiresShareMe.chat to do so.
​
<p>22. Disclaimer of Warranty and Limitation of Liability</p>
ShareMe.chat agrees that all services will be carried out with reasonable skill and care, and any goods supplied will be of satisfactory quality and reasonably fit for their purpose.
​
THE SERVICE, ShareMe.chat's SITES AND ALL RELATED PRODUCTS AND SERVICES ARE PROVIDED BY ShareME.chat "AS IS" WITH NO WARRANTIES WHATSOEVER. ALL EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED. TO THE FULLEST EXTENT PERMITTED BY LAW, ShareMe.chat DISCLAIMS ANY WARRANTIES FOR THE SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF THIS SERVICE.
YOU UNDERSTAND AND AGREE THAT THE SUBMISSION OF ANY DIGITAL CONTENT TO ShareMe.chat, AND THE DOWNLOAD OR UPLOAD OF ANY MATERIAL THROUGH THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY LOSS OR DAMAGE TO YOUR CONTENT OR IMAGE OR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT MAY RESULT IN THE DOWNLOAD OR UPLOAD OF ANY MATERIAL. YOU ARE SOLELY RESPONSIBLE FOR CREATING BACK-UPS OF YOUR DIGITAL CONTENT. EXCEPT AS EXPRESSLY PROVIDED IN THE PRECEDING PARAGRAPH, TO THE FULLEST EXTENT ALLOWED BY LAW, ShareMe.chat SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, AND PUNITIVE DAMAGES RESULTING FROM THE USE OR PROVISION OF THE SERVICE, ShareMe.Chat's SITE AND ALL RELATED PRODUCTS AND SERVICES, EVEN IF ShareMe.chat HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING DISCLAIMERS, WAIVERS AND LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
    </LongContent>
  );
}
export default LegalTerms;
