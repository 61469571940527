import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import GoToShareme from "./../components/GoToShareme";
import { requireAuth } from "./../util/auth";

function SuccessPage(props) {
  return (
    <>
      <Meta title="Integrations" />
      <HeroSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Successfully Installed"
        subtitle=""
      />
      <GoToShareme
              buttonText="Manage Messages"
              buttonColor="primary"
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Available Integrations"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(SuccessPage);
