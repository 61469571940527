import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function ClientsSection(props) {
  const items = [
    {
      name: "Cardinal Camera",
      image: "https://assets.simon.com/tenantlogos/18817.png",
      width: "150px",
    },
    {
      name: "Madison Photo Plus",
      image: "https://pbs.twimg.com/profile_images/869980117933506560/v6zA-QVJ_400x400.jpg",
      width: "135px",
    },
    {
      name: "Houston Camera Exchange",
      image: "https://pbs.twimg.com/profile_images/988256153497845760/77K5nQDL_400x400.jpg",
      width: "135px",
    },
    {
      name: "Fleetwood the Print Refinery",
      image: "https://b687712.smushcdn.com/687712/wp-content/uploads/sites/22/2023/06/PR-Fleetwood-logo.jpg?lossy=0&strip=1&webp=1",
      width: "175px",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
            textAlign="center"
          />
          <Grid container={true} justifyContent="center">
            {items.map((item, index) => (
              <Grid item={true} xs={12} md="auto" key={index}>
                <Box py={2} px={3}>
                  <img src={item.image} width={item.width} alt={item.name} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Section>
  );
}

export default ClientsSection;
